import React from "react";
import LoggerTable from "./views/LoggerTable.js";

export default function App() {
  return (
    <div>
      <LoggerTable />
    </div>
  );
}
